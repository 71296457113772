import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
//const { distance, closest } = require("fastest-levenshtein")

function SearchBar() {
  const [searchInput, setSearchInput] = useState("")
  const [showOverlay, setShowOverlay] = useState(false)
  const [showSearchList, setShowSearchList] = useState(false)
  //const [data, setData] = useState()

  const onChangeSearchInput = event => {
    if (event.target.value) {
      setShowOverlay(true)
      setShowSearchList(true)
    }
    setSearchInput(event.target.value)
  }

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          frontmatter {
            title
            description
            path
          }
          id
        }
      }
    }
  `)

  // console.log(data.allMarkdownRemark.nodes)
  const dataArray = data.allMarkdownRemark.nodes.map(node => node.frontmatter)
  //console.log(dataArray[0])

  const filteredArray = dataArray.filter(blog => {
    let key = searchInput.trim()
    if (!key) return false

    if (
      blog.title.toLowerCase().includes(key.toLowerCase()) ||
      blog.description.toLowerCase().includes(key.toLowerCase())
    )
      return true

    const inputArray = key.toLowerCase().split(/(?:,| )+/)
    const titleArray = blog.title.toLowerCase().split(/(?:,| )+/)
    //const descriptionArray = blog.description.toLowerCase().split(/(?:,| )+/)
    //const sampleSpace = titleArray.concat(descriptionArray)

    //console.log(sampleSpace)
    //console.log(inputArray)

    for (let x of inputArray)
      if (titleArray.includes(x.toLocaleLowerCase())) return true

    return false
  })

  //console.log(filteredArray)

  return (
    <>
      {showOverlay && (
        <div
          className="fixed top-0 left-0 w-screen h-screen z-20 "
          onClick={() => {
            setShowOverlay(false)
            setShowSearchList(false)
          }}
        ></div>
      )}
      <div className="z-40 relative w-80 lg:w-56 xl:w-80">
        <div
          id="SearchBar"
          className="z-40 relative flex justify-between text-gray-500 items-center rounded-3xl justify-self-center border hover:border bg-white border-gray-200 hover:border-new-green"
        >
          <input
            type="text"
            id="SearchBar"
            placeholder="Search blogs by keyword"
            value={searchInput}
            autocomplete="off"
            onChange={onChangeSearchInput}
            onKeyUp={onChangeSearchInput}
            onFocus={onChangeSearchInput}
            onInput={onChangeSearchInput}
            //onBlur={onBlurHandler}
            className="z-40 w-11/12 px-4 py-3 text-xs text-gray-500 rounded-3xl tracking-wider hover:text-gray-900 border-none block outline-none pb-3"
          />
          <div
            id="SearchIcon"
            className="z-40 outline-none pr-4 text-gray-500 hower:text-new-green cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-search"
            >
              <circle cx="11" cy="11" r="8" />
              <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
          </div>
        </div>

        {showSearchList && (
          <div
            className="absolute top-15 z-40 w-full sm:w-96 lg:w-80 mt-1 bg-white max-h-96 overflow-y-scroll "
            //onClick={onFocusHandler}
          >
            {filteredArray.map(blog => (
              <a href={blog.path}>
                <div className="px-4 py-2 border border-gray-300  text-new-green">
                  <h1>{blog.title}</h1>
                </div>
              </a>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default SearchBar
