import React from "react"
import { Link } from "gatsby"

export default class Footer extends React.Component {
  render() {
    return (
      <div className="max-w-screen-lg mx-auto bg-white mt-4 mb-8">
        <nav className="text-center tracking-wider text-base sm:text-sm 2xl:text-lg grid sm:grid-cols-2 lg:grid-cols-4 justify-center">
          <div className="py-2">
            <Link
              href="https://www.enjoyalgorithms.com/coding-interview/"
              className="text-gray-500 hover:text-green-500 underline"
            >
              Coding interview blogs
            </Link>
          </div>

          <div className="py-2">
            <Link
              href="https://www.enjoyalgorithms.com/machine-learning/"
              className="text-gray-500 hover:text-green-500 underline"
            >
              Machine learning blogs
            </Link>
          </div>

          <div className="py-2">
            <Link
              href="https://www.enjoyalgorithms.com/system-design/"
              className="text-gray-500 hover:text-green-500 underline"
            >
              System design blogs
            </Link>
          </div>

          <div className="py-2">
            <Link
              href="https://www.enjoyalgorithms.com/oops-concepts/"
              className="text-gray-500 hover:text-green-500 underline"
            >
              OOPS design blogs
            </Link>
          </div>
        </nav>

        <div className="flex justify-center -ml-4 mt-8 md:order-2">
          <p className=" tracking-wider text-sm 2xl:text-base mt-3 mr-2 leading-6 text-center text-gray-500">
            Follow us on:
          </p>

          <a
            href="https://www.linkedin.com/company/enjoy-algorithms/"
            className="mt-2 text-gray-500 hover:text-new-green"
          >
            <span className="sr-only">Linkedin</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-linkedin"
            >
              <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
              <rect x="2" y="9" width="4" height="12" />
              <circle cx="4" cy="4" r="2" />
            </svg>
          </a>
          <a
            href="https://medium.com/enjoy-algorithm"
            className="ml-3 mt-2 text-gray-500 hover:text-new-green"
          >
            <span className="sr-only">Medium</span>
            <svg
              x="0px"
              y="0px"
              width="50"
              height="50"
              viewBox="0 0 48 48"
              className="w-6 h-6"
            >
              {" "}
              <path d="M45,4H5C4.448,4,4,4.448,4,5v40c0,0.552,0.448,1,1,1h40c0.552,0,1-0.448,1-1V5C46,4.448,45.552,4,45,4z M40,13.5 l-1.821,2.197C38.064,15.811,38,15.965,38,16.125V32.75c0,0.16,0.064,0.314,0.179,0.428L40,35.546V36H30v-0.454l2.821-2.368 C32.936,33.064,33,32.91,33,32.75V17.879L24.848,36h-0.001h-1.543l0,0L15,18.375v13.108c0,0.22,0.076,0.433,0.215,0.605L18,35.546 V36h-8v-0.454l2.783-3.438C12.923,31.936,13,31.721,13,31.5V16.388c0-0.142-0.05-0.279-0.142-0.388L11,13.5V13h7.097l7.624,16.183 L33.002,13H40V13.5z"></path>
            </svg>
          </a>

          <a
            href="https://www.facebook.com/enjoyalgorithms"
            className=" mt-2 ml-4 text-gray-500 hover:text-new-green"
          >
            <span className="sr-only">Facebook</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-facebook"
            >
              <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
            </svg>
          </a>
        </div>

        <div className="mt-4 md:order-1">
          <p className="text-sm 2xl:text-base tracking-wider leading-6 text-center text-gray-500">
            © 2020 Code Algorithms Pvt. Ltd.
          </p>
          <p className="text-sm 2xl:text-base tracking-wider  leading-6 text-center text-gray-500">
            All rights reserved.
          </p>
        </div>
      </div>
    )
  }
}
