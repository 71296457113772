import React from "react"
import { Link } from "gatsby"
import emLogo from "../img/Em-logo.svg"
import SearchBar from "./SearchBar"

export default class NavigationBar extends React.Component {
  // const [menuOpen, setMenuOpen] = React.useState(false)
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: 0,
    }
  }
  render() {
    return (
      <div>
        <div className="flex bg-white border border-gray-50 flex-wrap ">
          <div className="w-full mt-1 mb-1">
            <nav className="relative max-w-screen-2xl md:mx-auto px-4 md:px-6 xl:px-10 flex flex-wrap items-center justify-between rounded navbar-expand-md">
              <div className="container flex flex-wrap items-center justify-between py-2">
                <div className="relative flex justify-between w-full lg:w-auto lg:block lg:justify-start">
                  <Link to="/" className="z-30">
                    <img
                      className="z-30 w-auto h-12"
                      src={emLogo}
                      alt="enjoyamathematics logo"
                    />
                  </Link>
                  <div className=" hidden mt-2 sm:block lg:hidden">
                    <SearchBar />
                  </div>
                  <div className="z-30 flex flex-wrap items-end">
                    <button
                      className="z-30 block px-3 py-2 text-xl leading-none text-black border border-solid rounded outline-none cursor-pointer lg:hidden focus:outline-none"
                      type="button"
                      id="menuButton"
                      aria-label="Menu Icon"
                      onClick={e => {
                        this.setState({
                          menuOpen: !this.state.menuOpen,
                        })
                      }}
                    >
                      <svg
                        className="w-6 h-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="flex justify-end ">
                  <div className="hidden mt-1 xl:mr-2 xl:mr-6 mb-1 lg:block">
                    <SearchBar />
                  </div>
                  <div
                    className={
                      "lg:flex  flex-grow items-center" +
                      (this.state.menuOpen ? " flex" : " hidden")
                    }
                    id="example-navbar-info"
                  >
                    <ul className="flex tracking-wider mt-3 lg:mt-0 flex-col text-base text-new-green lg:text-gray-500 text-base lg:text-sm 2xl:text-base  list-none lg:flex-row lg:ml-auto">
                      <li className="z-30 ml-1 py-1">
                        <a
                          className=" flex px-3 py-2 hover:text-new-green hover:underline"
                          href="https://www.enjoyalgorithms.com/data-structures-and-algorithms-course/"
                        >
                          DSA Course
                        </a>
                      </li>
                      <li className="z-30 ml-1 py-1">
                        <a
                          className=" flex px-3 py-2 hover:text-new-green hover:underline"
                          href="https://www.enjoyalgorithms.com/machine-learning-courses/"
                        >
                          ML Course
                        </a>
                      </li>
                      <li className="z-30 ml-1 py-1">
                        <a
                          className=" flex px-3 py-2 hover:text-new-green hover:underline"
                          href="https://www.enjoyalgorithms.com/system-design-courses/"
                        >
                          SD Course
                        </a>
                      </li>
                      <li className="z-30 ml-1 py-1">
                        <a
                          className=" flex px-3 py-2 hover:text-new-green hover:underline"
                          href="https://www.enjoyalgorithms.com/oops-course/"
                        >
                          OOP Course
                        </a>
                      </li>
                      <li className="z-30 nav-item ml-1 py-1">
                        <a
                          className=" flex px-3 py-2 hover:text-new-green hover:underline"
                          href="https://www.enjoyalgorithms.com"
                        >
                          EnjoyAlgorithms
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className="sm:hidden px-6 mt-3 flex justify-center">
          <SearchBar />
        </div>
      </div>
    )
  }
}